<template>
    <section class="notfound-wrapper">
        <div class="content">
            <img src="./img/notFound.png" alt="">
            <p>呀～页面找不到了</p>
        </div>
    </section>
</template>

<script>
    'use strict';
    export default {
        name: "NotFound",
        data() {
            return {}
        },
        methods: {

        },
        components: {

        }
    };
</script>

<style lang="scss" scoped>
    .notfound-wrapper {
        background-color: #FFF;
        height: 100%;
        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            p {
                font-size: 20px;
                color: #868E9E;
                margin-top: 34px;
                text-align: center;
            }
        }
    }
</style>
